.codeEditor{
    width: 100%;
    background: #1e1e1e;
    height: 100%;
    overflow-x: auto;

    .choose-contract{
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        gap: 10px;
        position: relative;
        padding-top: 25px;
        .back-btn{
            top: 2px;
            position: absolute;
            color: #FFF;
            border: 1px gray solid;
            border-radius: 2px;
        }
        .cm-line{
            height: 8px;
            font-size: 8px;
        }
        .cm-gutterElement{
            height: 8px;
            font-size: 8px;
        }
        .choose-template{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            button{
                color: #FFF;
                border: 1px white solid;
                padding: 5px;
                margin-top: 2px;
                cursor: pointer;
            }
        }
    }
    .choose-contract > div:hover{
        scale: 1.05;
        .cm-theme{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            transition: 200ms;
        }
        .cm-editor{
            height: 180px !important;
            width: 270px !important;
            transition: 200ms;
        }
        
    }
    .choose-contract > div{
        border: 1px gray solid;
        color: #FFF;
        padding: 20px;
        width: 300px;
        height: 200px;
        background: #222336;
        border-radius: 2px;
        transition: 500ms;
        cursor: pointer;
        position: relative;
        h3{
            margin: 0;
        }
        button{
            transition: 200ms;
        }
        button:hover{
            border-color: rgb(52, 83, 183) !important;
        }
    }
}
.header{
    height: 50px;
    width: 100%;
    background: #222336;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    .contractsSelect{
        background: rgb(16, 110, 190);
        width: 200px;
        border-radius: 2px;
        position: absolute;
        top: 2.6rem;
        z-index: 9;
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
    }

    .first_div{
        min-width: 300px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: start;

        padding: 10px;
        color: #FFF;

        .contracts-select:hover{
            background: gray;
        }
        .contracts-select{
            transition: 200ms;
            width: 200px;
            text-align: start;
            color: #FFF;
            font-size: 20px;
            font-weight: 600;
            background-color: rgb(16, 110, 190);
            border-radius: 2px;
            padding: 5px 10px;

            display: flex;
            justify-content: space-between;
            p{
                margin: 0;
                
            }
            position: relative;
            p:last-child{
                width: 16.5px;
                display: flex;
                position: absolute;
                top: 50%;
                transform: -50%;
                right: 5px;
                rotate: 180deg;
                span:first-child{
                    min-width: 10px;
                    display: block;
                    height: 1px;
                    background: #FFF;
                    rotate: 135deg;
                    position: absolute;
                    left: 0;
                }
                span:last-child{
                    min-width: 10px;
                    display: block;
                    height: 1px;
                    background: #FFF;
                    rotate: 45deg;
                    position: absolute;
                    right: 0;
                }
            }
            
        }
        .contracts-selects{
            color: #FFF;
            display: flex;
            align-items: center;
            justify-content: space-between;
            button{
                color: #FFF;
                font-weight: 600;
                width: 100%;
                height: 100%;
                text-align: start;
                margin: 10px;
            }
            button:hover{
                background: gray;
            }
            transition: 200ms;
        }
    }
    .guilde-header{
        font-size: 600;
        text-decoration: none;
        color: gray;
        display: flex;
        align-items: center;
        justify-content: center;

        span{
            border: 2px gray solid;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            margin-right: 5px;

            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .connect-btn{
        transition: 200ms;
        width: 200px;
        text-align: start;
        color: #FFF;
        font-size: 20px;
        font-weight: 600;
        background-color: rgb(16, 110, 190);
        border-radius: 2px;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        position: relative;
    }
    .connect-btn:hover{
        // background: gray;
    }
    .logout-btn{
        border: 1px #FFF solid;
        padding: 5px 15px;
        color: #FFF;
        font-size: 20px;
        font-weight: 600;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        border-radius: 15px;
        button{
            padding: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .network-link-p{
        border: 1px gray solid;
        padding: 5px 25px;
        border-radius: 15px;
        input{
            width: 100%;
            color: #FFF;
            padding: 0;
            margin: 0;
            background: none;
            border: none;
            width: 300px;
            text-align: center;
        }
        input:focus{
            outline: none;
        }
    }
}
.triangle{
    width: 10px;
    fill: #FFF;

    rotate: 90deg;
    transition: 300ms;
}
.footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 50px;
    background: #222336;
    width: 100%;

    .build-panel{
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 50px;
        button{
            color:#FFF;
            border: 1px gray solid;
            border-radius: 15px;
            padding: 5px 20px;

            transition: 500ms;
        }
        button:hover{
            border-color: blue;
        }
        button:disabled{
            color: gray;
            border: none !important;
        }
        input{
            width: 100%;
        }
        .console-open-btn{
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
        }
    }
    .footer-console{
        width: 100%;
        background: #1e1e1e;
        color: #FFF;
        height: 200px;
        padding: 10px 30px;
        overflow-y: scroll;
    }
    .error{
        background: red;
        white-space: break-spaces;
    }
    
}
.navbar{
    position: fixed;
    width: 100px;
    background: #222336;
    height: 100%;
    z-index: 9999;
    top: 0;
    left: -97px;
    transition: 200ms;

    display: flex;
    align-items: center;
    justify-content: start;
    font-weight: 600;
    padding: 10px;
    flex-direction: column;
    color: #FFF;
    gap: 20px;

    .navbar-link{
        a{
            color: #FFF;
            width: 80%;
            transition: 200ms;
        }
        a:hover{
            color: #1e1e1e;
        }
    }
    .navbar-arrow{
        background: #222336;
        position: absolute;
        bottom: 20%;
        right: -15px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px black solid;

        border-radius: 0 100px 100px 0;
        padding-left: 5px;

    }

    .first_div{
        display: flex;
        align-items: center;
        justify-content: start;
        
        color: #FFF;
        width: 100%;

        
    }
}
.navbar:hover{
    left: 0;
}
.toast{
    position: absolute;
    right: 5px;
    top: 100px;
    z-index: 9999;
    background: green;
    padding: 10px;
    border-radius: 5px;

    color: #FFF;
    font-weight: 600;

    max-width: 300px;
    max-height: 400px;
    p{
        padding: 0;
        margin: 0;
    }
    p:last-child{
        font-weight: 400;
    }
}