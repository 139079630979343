.App {
  width: 100%;
  height: 100%;
}
html,body{
  height: 100%;
}

#root{
  height: 100%;
}
.App > div{
  border: 1px black solid;
}
button{
  background: none;
  border: none;
  cursor: pointer;
}
.files-tabs{
  height: 100%;
  padding: 0 10px;
  background: gray;
  color: #FFF;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px black solid;
  border-bottom: none;
  button{
    color: #FFF;
  }
}

.create-files-div{
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: end;
  button{
    border: none;
    background: none;
    height: 100%;
    display: flex;
    align-items: center;

    transition: 100ms;
  }
  button:hover{
    background: gray;
  }
  .folder-svg{
    width: 20px;
    padding: 10px;
    fill: #FFF;

    box-sizing: unset;
  }
}
.create-modal{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  color: #FFF;
  background: rebeccapurple;
  border-radius: 15px;
  text-align: center;
  padding: 20px;
  z-index: 9999;
  p,h3{
    margin: 0;
    margin-bottom: 10px;
  }

  button{
    color: #FFF;
    font-size: 20px;
    margin-top: 10px;
  }

  .close{
    position: absolute;
    right: 5px;
    top: 5px;
  }
}
.main-div{
  height: inherit;
  display: flex;
  .folder-panel{
    min-width: 300px;
    width: 300px;
    position: relative;
    height: 100%;
    border-right: 1px black solid;
    button{
      text-align: start;
    }
  }
}