body {
  margin: 0;
  padding: 0;
  background: #2b2b2b;
}
*{
  box-sizing: border-box;
  font-family: 'Montserrat' !important;
}
.lock-screen{
  display: none;
}
@media (max-width: 850px) {
  .lock-screen{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999999;
    background: #FFF;
    top: 0;
    left: 0;
  
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    font-weight: 600;

    text-align: center;
    padding: 5px;
  }
}
.close-input{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
}